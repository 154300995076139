/* Global */

html, body {
    font-family: arial !important;
}

.bold {
    font-weight: bold;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.center {
    text-align: center !important;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.blue {
    color: blue !important;
}

.orange {
    color: orange !important;
}

.red {
    color: red !important;
}

.input-form-group {
    margin: 8px;
}

.button-group {
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    z-index: 0;
}

.function-button {
    margin: 10px;
    /*z-index: -999 !important;*/
}

.function-card {
    border: solid grey 1px;
    background: #E3F2FD;
    margin: 0px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    width: 100% !important;
}

.function-card-spreadsheet {
    border: solid grey 1px;
    background: lightblue;
    margin: 0px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    width: 100% !important;
}

.DayPicker-NavButton--prev, .DayPicker-NavButton--next {
    margin-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

.bp4-icon-double-caret-vertical {
    display: none !important;
}

.MuiInputAutocomplete-formControl {
    margin-left: 2px !important;
    margin-right: 4px !important;
}

.MuiInputBase-formControl {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.text-field {
    margin-left: 2px !important;
    margin-right: 2px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

input[type='number'] {
    text-align: right !important;
}

.switch-mode {
    font-size: 12pt;
    text-align: left;
    margin-top: 14px;
    margin-right: 34px;
}

.switch-mode-label {
    font-size: 12pt;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.column {
    flex: 25%;
    padding: 5px;
}

.helper-text {
    font-size: 10pt;
    display: flex;
    justify-content: center;
}

.main-container {
    margin-top: 2px;
    margin-right: 2px;
    margin-left: 2px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 90vh !important;
}

.next-step-container {
    width: 100% !important;
}

.next-step-button {
    animation: glowing 1300ms infinite !important;
    float: right;
    font-weight: bold;
    margin-top: 10px;
    background-color: aqua !important;
}

@keyframes glowing {
    0% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
    50% {
      background-color: #49e819;
      box-shadow: 0 0 20px #49e819;
    }
    100% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
  }

 /* For mobile phones portrait: */
@media only screen and (max-width: 599px) {
    .col-sm-1 {width: 8.33% !important;}
    .col-sm-2 {width: 16.66% !important;}
    .col-sm-3 {width: 25% !important;}
    .col-sm-4 {width: 33.33% !important;}
    .col-sm-5 {width: 41.66% !important;}
    .col-sm-6 {width: 50% !important;}
    .col-sm-7 {width: 58.33% !important;}
    .col-sm-8 {width: 66.66% !important;}
    .col-sm-9 {width: 75% !important;}
    .col-sm-10 {width: 83.33% !important;}
    .col-sm-11 {width: 91.66% !important;}
    .col-sm-12 {width: 100% !important;}
    .navbar-email-text, .clock, /*.function-button .bp3-button-text, .switch-mode-label,*/ .navbar-breadcrumb {
        display: none !important;
    }
    .button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .function-button {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }
    .bp3-dialog {
        min-width: 100% !important;
    }
    .location-tree {
        margin-top: 10px !important;
    }
    .function-sub-title {
        font-size: 10pt !important;
    }
    .tbl-group-sm {
        width: 150%;
    }
    .tbl-group-md {
        width: 200%;
    }
    .tbl-group-lg {
        width: 250%;
    }
    .tbl-group-xl {
        width: 300%;
    }
    .tbl-parent {
        overflow: scroll !important;
    }
}

 /* For mobile phones landscape: */
 @media only screen and (max-width: 896px) and (max-height: 599px) {
    .col-sm-1 {width: 8.33% !important;}
    .col-sm-2 {width: 16.66% !important;}
    .col-sm-3 {width: 25% !important;}
    .col-sm-4 {width: 33.33% !important;}
    .col-sm-5 {width: 41.66% !important;}
    .col-sm-6 {width: 50% !important;}
    .col-sm-7 {width: 58.33% !important;}
    .col-sm-8 {width: 66.66% !important;}
    .col-sm-9 {width: 75% !important;}
    .col-sm-10 {width: 83.33% !important;}
    .col-sm-11 {width: 91.66% !important;}
    .col-sm-12 {width: 100% !important;}
    .navbar-email-text, .clock {
        display: none !important;
    }
    .button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .function-button {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }
    .bp3-dialog {
        min-width: 100% !important;
    }
    .location-tree {
        margin-top: 10px;
    }
    .function-sub-title {
        font-size: 11pt !important;
    }
    .tbl-group-sm {
        width: 120%;
    }
    .tbl-group-md {
        width: 170%;
    }
    .tbl-group-lg {
        width: 220%;
    }
    .tbl-group-xl {
        width: 270%;
    }
    .tbl-parent {
        overflow: scroll !important;
    }
}

/* For tablets: */
@media only screen and (min-width: 600px) and (min-height: 600px) {
    .col-md-1 {width: 8.33% !important;}
    .col-md-2 {width: 16.66% !important;}
    .col-md-3 {width: 25% !important;}
    .col-md-4 {width: 33.33% !important;}
    .col-md-5 {width: 41.66% !important;}
    .col-md-6 {width: 50% !important;}
    .col-md-7 {width: 58.33% !important;}
    .col-md-8 {width: 66.66% !important;}
    .col-md-9 {width: 75% !important;}
    .col-md-10 {width: 83.33% !important;}
    .col-md-11 {width: 91.66% !important;}
    .col-md-12 {width: 100% !important;}
}

/* For desktop: */
@media only screen and (min-width: 1024px) {
    .col-lg-1 {width: 8.33% !important;}
    .col-lg-2 {width: 16.66% !important;}
    .col-lg-3 {width: 25% !important;}
    .col-lg-4 {width: 33.33% !important;}
    .col-lg-5 {width: 41.66% !important;}
    .col-lg-6 {width: 50% !important;}
    .col-lg-7 {width: 58.33% !important;}
    .col-lg-8 {width: 66.66% !important;}
    .col-lg-9 {width: 75% !important;}
    .col-lg-10 {width: 83.33% !important;}
    .col-lg-11 {width: 91.66% !important;}
    .col-lg-12 {width: 100% !important;}
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 3px !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 3px !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-2 {
    margin-right: 2px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.ml-1 {
    margin-left: 1px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-5 {
    margin-top: 10px !important;
}

.mb-1 {
    margin-bottom: 1px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 10px !important;
}

.application-drawer {
    background-color: #E3F2FD;
}

/* Auth */

.auth-form {
    margin-top: 100px;
    text-align: center;
    font-size: 11pt;
    width:  290px;
}

@media (prefers-reduced-motion: no-preference) {
    .auth-logo {
        pointer-events: none;
        animation: auth-logo-float infinite 3s ease-in-out;
    }
}

@keyframes auth-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.auth-register-prompt {
    font-size: 10pt;
    color: blue;
    font-weight: bold;
}






/* Create Account */


.create-account-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.bp3-dialog-footer-actions {
    justify-content: left;
}

.subscription-card {
    word-wrap: break-word;
    margin: 5px;
    border: solid lightgrey 1px;
    height: auto;
}



/* Navigation */
.navbar-breadcrumb {
    margin-left: 50px;
}

.navbar-buttons {
    margin-left: auto;
}

.navbar-logout-button {
    margin-right: 30px !important;
}

.navbar-email-text {
    font-size: 10pt;
    font-weight: bold;
}






/* Dashboard */

.dashboard-statistics {
    height: 55vh;
    margin-bottom: 5px;
    border: solid 1px lightgrey;
    border-radius: 5px;
}

.dashboard-app-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    background: lightblue !important;
    width: 98vw;
    padding-top: 2px;
    padding-bottom: 2px;
}

.dashboard-app-wrapper {
    text-align: center;
    padding: 4px;
}

.dashboard-app-card-enabled {
    padding: 10px;
    height: 110px;
}

.dashboard-app-card-enabled .bp3-icon {
    transition: transform .5s ease;
}

.bp3-breadcrumb-current:hover {
  color: white;
}

.dashboard-app-card-enabled:hover .bp3-icon {
    transform: scale(1.5);
    padding: 10px;
}
.dashboard-app-card-disabled {
    padding: 10px;
    height: 110px;
}

.dashboard-app-name {
    font-size: 12pt;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.dashboard-app-link {
    pointer-events: auto;
}

.dashboard-app-link-disabled {
    pointer-events: none;
}

.dashboard-enabled-icon {
    padding: 0px !important;
    color: darkblue !important;
}

.dashboard-disabled-icon {
    padding: 0px !important;
    color: orange !important;
}






/* App */

.app-company-selector {
    font-size: 11pt !important;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.app-selector {
    font-size: 11pt !important;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.app-company-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app-copyright-text {
    text-align: center;
    color: white;
}

.app-status-bar {
    font-size: 10pt;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #394b59;
    position: static;
    bottom: 0;
    width: 100%;
}











/* Note  */
.note-container {
    text-align: right;
}

.note-content {
    font-size: 9pt !important;
    text-align: justify;
}

.note-collapse {
    margin-top: 5px;
    margin-bottom: 7px;
}

.note-text {
    font-weight: bold;
    font-size: 9pt;
}

.implementation-notice-text {
    margin: 5px;
    text-align: justify;
    text-justify: inter-word;
    color: grey;
}

.notifier-text {
    font-size: 9pt;
}

.notifier-total-count {
    font-size: 9pt !important;
    font-weight: bolder;
}







/* Clock */
.clock {
    margin-right: 10px;
    position: absolute;
    right: 0;
    color: white;
}








/* Warehouse Inventory  */
.wh-module-container {
    width: 95vw;
    text-align: center;
    margin-bottom: 13px;
}

.wh-module-title {
    margin: 35px;
    text-align: center;
    text-transform: uppercase;
}

.wh-module-button {
    height: 100px;
    margin: 2px;
    text-transform: uppercase;
    font-weight: bold;
}








/* Function Drawer  */

.fd-email {
    text-align: center;
}

.fd-header-title {
    margin-left: 12px;
}

.fd-role-container {
    margin-left: 5px;
}

.fd-role-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.fd-menu-item {
    font-weight: bold;
    font-size: 11pt;
}

.bp3-intent-warning {
    background-color: lightcyan !important;
}








/* Worksheet */
.worksheet-table {
    display: block;
    width: 98vw !important;
    height: 70vh !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 5px !important;
}

.stock-count-sheet-table {
    display: block;
    width: 97vw;
    height: 60vh !important;
    padding: 0px !important;
    margin: 0px !important;
}







/* Permission  */
.permission-title {
    text-align: left !important;
    font-size: 14pt !important;
    font-weight: bold;
}

.permission-title .bp3-checkbox {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.permission-checkbox {
    font-size: 11pt;
    font-weight: normal;
    padding: 0px !important;
    margin: 0px !important;
}







/* Authorizers  */
.authorizer-up-to {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11pt;
}






/* General Settings  */
.gs-stock-count-calendar {
    border-radius: 2px;
    border: solid grey 2px;
    padding: 10px !important;
    margin: 0px !important;
    display: flex;
    justify-content: center !important;
}



.gs-section-title {
    margin-top: 10px;
    margin-bottom: 20px;
}

.stock-count-schedules {
  column-count: 2;
  font-size: 10pt;
  color: blue;
  text-align: center;
}

.accounting-periods {
    column-count: 4;
    font-size: 10pt;
    color: blue;
    text-align: left;
  }






/* Locations  */
.location-group-info {
    margin-top: 10px;
    margin-bottom: 10px;
    column-count: 3;
    color: black;
    font-size: 10pt;
    font-weight: normal;
}

.location-tree {
    text-align: left !important;
    background: white;
    font-size: 9pt;
}












.item-info-table td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

@media screen and (max-width: 992px) {
    .column {
        flex: 50%;
    }
}

@media screen and (max-width: 600px) {
    .row {
        flex-direction: column;
    }
}

.bp3-tree-node-caret {
    margin-left: 20px;
}

.bp3-tree-node-label {
    width: 50vw !important;
}

.bp3-tree-node-secondary-label {
    margin-right: 20px;
}

.control-group {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.control-group label {
    font-size: 10pt;
}

.control-group input {
    font-size: 10pt;
}

.input-field {
    padding: 10px;
}

.input-field-code {
    width: 345px;
}

.input-field-desc {
    width: 345px;
}

.input-field-amount {
    width: 345px;
}

.bp3-switch-inner-text {
    font-weight: bold;
    font-size: 8pt !important;
}

.notifier {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.App-link {
    color: rgb(112, 76, 182);
}

.App-profile-section {
    width: 50%;
}

.App-helper {
    margin-bottom: 20px;
    padding: 0px;
    text-align: left;
}

.circle {
  display: flex;
  width: 30px;
  height: 30px;
  background-color: lightgrey;
  border-radius: 50%;
}

.text {
  margin: auto;
  font-size: 11pt;
}

.form-control {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.MuiInputLabel-formControl {
    font-size: 10pt !important;
}

.MuiInputBase-formControl {
    font-size: 10pt !important;
}

.line-item-table-header {
    background: "white";
    position:"sticky";
    top: 0;
    z-index: 11;
}

.input-line {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    padding-left: 0px !important;
    padding-left: 0px !important;
}

.line-item-table th {
    background: #7FFFD4;
    color: black !important;
    text-align: center;
}

.line-item-table td {
    padding: 0px !important;
}

.line-number {
    width: 10px !important;
}

.line-number button {
    font-size: 9pt !important;
}

.line-item {
    width: 300px !important;
    text-align: center !important;
}

.item-condition {
    width: 140px !important;
}

.quantity {
    width: 130px !important;
    text-align: right !important;
}

.unit-cost {
    width: 120px !important;
    text-align: right !important;
}

.total-amount {
    width: 130px !important;
    text-align: right !important;
}

.uom {
    width: 120px !important;
    text-align: center !important;
}

.location {
    width: 160px !important;
    text-align: center !important;
}

.account-code {
    width: 100px !important;
}

.account-description {
    width: 230px !important;
}

.profit-cost-center {
    width: 100px !important;
}

.debit-amount, .credit-amount {
    width: 90px !important;
}

.delete-line-button {
    width: 40px !important;
    text-align: center !important;
}

.view-details-button {
    width: 40px !important;
}

.loading-screen {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loading-screen-text {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 200px;
    margin-top: 14px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11pt;
    font-weight: bold;
    color: blue;
}

.omnibar-select {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bp3-input {
    font-size: 10pt !important;
}

.form-card {
    margin-bottom: 10px !important;
}

.action-column {
    width: 90px !important;
}

.function-table {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    table-layout: fixed;
}

.manual-tab-container {
    display: block;
    width: 1400px;
    height: 450px;
    padding: 0px;
    margin-top: 10px;
    font-size: 11pt;
}

.manual-tab {
    margin-left: 10px;
}

.function-sub-title {
    font-size: 12pt;
    margin-top: 10px;
    color: blue;
    margin-bottom: 20px;
    text-align: center;
}

.function-title {
    font-size: 17pt;
    margin-top: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.spreadsheet-tab {
    text-align: center;
}

.bp3-table-column-name {
    text-align: center !important;
    font-weight: bold;
}

/* App  */
.company-logo {
    /*width: 130px;
    height: 130px;*/
    width: 130px;
    height: 37px;
    margin-top: 7px;
}

.company-container {
    margin-top: 15px;
    text-align: left;
    padding-left: 10px;
    display: flex;
}

.profit-cost-center-label {
    font-weight: bold;
    font-size: 8pt;
}

.profit-cost-center-value {
    font-style: italic;
    font-size: 8pt;
}

.profit-cost-center-tag {
    margin: 1px;
    color: black !important;
}

.App-header {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-field-conversion {
    width: 400px;
}

.inventory-tracking-input {
    padding: 2px;
    min-width: 80px;
}

.journal-entry-table tbody td {
    padding-top: 3px !important;
    padding-bottom: 2px !important;
}

.journal-entry-table tfoot th {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.pending-status {
    color: red !important;
    font-weight: bold;
}

.pending-transaction-row td {
    font-family: Arial !important;
}

.react-menu-item {
    font-size: 10pt !important;
    font-weight: bold;
}

.react-menu-item-disabled {
    font-size: 10pt !important;
    color: grey;
    cursor: not-allowed !important;
    pointer-events: none !important;
}

.message-row {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    border-top: solid 1px;
    border-bottom: solid 1px;
    border-color: lightgrey;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.message-row:hover {
    cursor: pointer;
    background-color: aquamarine;
}

.laundry-machine-set-container {
    font-size: 10pt;
    margin: 5px;
    height: 310px;
}

.laundry-machine-set-card {
    font-size: 10pt;
    height: 90px;
}

.laundry-machine-set-status {
    color: red;
    font-size: 9pt;
    font-weight: bold;
}

.pos-item-container {
    font-size: 9pt;
    margin: 5px;
    width: 260px;
    height: 100px;
}

.pos-item-image-card {
    width: 100px;
    height: 100px;
}

.pos-item-details-card {
    font-size: 9pt;
    padding: 2px;
    width: 160px;
    height: 100px;
}

.pos-item-details-card, .pos-item-image-card:hover {
    cursor: pointer;
}

.pos-service-container {
    font-size: 9pt;
    margin: 5px;
    width: 300px;
    height: 100px;
}

.pos-service-details-card, .pos-service-image-card:hover {
    cursor: pointer;
}

.pos-service-image-card {
    width: 10px;
    height: 100px;
}

.pos-service-details-card {
    font-size: 9pt;
    padding: 2px;
    width: 130px;
    height: 100px;
}
